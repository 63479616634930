import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CustomContainer from '../CustomContainer/CustomContainer';
import Typography from '@mui/material/Typography';
import TrackCard from './TrackCard';
import { getFormattedTracks } from './TracksList';
import classes from './Tracks.module.css';
import { Link } from 'react-router-dom';
import useLocale from '../../../CustomHooks/useLocale';
import { NAVIGATION_ROUTES } from '../../../const';

function Tracks() {
    const tracksList = getFormattedTracks();
    const { localeUrlPrefix } = useLocale();

    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

    const handleExpandClick = (index: number) => {
        if (expandedIndex === index) {
            setExpandedIndex(null);
        } else {
            setExpandedIndex(index);
        }
    };

    return (
        <section id="maps" className={classes['tracks']} aria-labelledby="maps_title">
            <CustomContainer>
                <Typography
                    variant="h2"
                    component="h2"
                    className={classes['tracks__title']}
                    id="maps_title"
                >
                    <FormattedMessage
                        defaultMessage="Карты"
                        id="Paths_JcurHw"
                        description="карты"
                    />
                </Typography>
                <div className={classes['tracks__list']}>
                    {tracksList.map((card, index) => (
                        <TrackCard
                            key={index}
                            index={index}
                            card={card}
                            expanded={index === expandedIndex}
                            onExpandClick={handleExpandClick}
                        />
                    ))}
                </div>
            </CustomContainer>
        </section>
    );
}

export default Tracks;
