import { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import useLocale from '../../../../../CustomHooks/useLocale';
import { buildApiUrl } from '../../../../../services/api';
import { LOCALE_MAP, SUBSCRIPTION_TYPE_MAP } from '../../../../../const';

const paymentStatusMap: Record<string, string> = {
    created: 'Создан',
    succeeded: 'Оплачен',
    pending: 'В обработке',
    canceled: 'Отменён'
};

export async function fetchPaymentHistory() {
    try {
        const response = await fetch(buildApiUrl('/payment/history'), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch payment history');
        }

        const data = await response.json();
        return data.payments;
    } catch (error) {
        console.error('Failed to fetch payment history:', error);
        return [];
    }
}

type Payment = {
    orderId: number;
    amount: number;
    status: string;
    createdAt: string;
    subscription: {
        id: string;
        type: string;
    };
};

export const CabinetSubscriptionPayments: React.FC = () => {
    const { locale } = useLocale();
    const [paymentHistory, setPaymentHistory] = useState<Payment[]>([]);

    const loadPaymentHistory = async () => {
        const payments = await fetchPaymentHistory();
        setPaymentHistory(payments);
    };

    useEffect(() => {
        loadPaymentHistory();
    }, []);

    const mapSubscriptionType = (type: string): string => {
        return SUBSCRIPTION_TYPE_MAP[type] || type;
    };

    const mapPaymentStatus = (status: string): string => {
        return paymentStatusMap[status] || status;
    };

    return (
        <Box mt={4}>
            <Typography variant="h3">История оплат</Typography>
            {paymentHistory.length === 0 ? (
                <Typography>Вы ещё не производили оплат.</Typography>
            ) : (
                <>
                    <Button
                        startIcon={<RefreshIcon />}
                        variant="contained"
                        color="primary"
                        onClick={loadPaymentHistory}
                        sx={{ marginBottom: 3, marginTop: 2 }}
                    >
                        Обновить статус оплат
                    </Button>
                    <Table sx={{ display: { xs: 'block', sm: 'table' }, overflowX: 'auto' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>№</TableCell>
                                <TableCell>Номер заказа</TableCell>
                                <TableCell>Тариф</TableCell>
                                <TableCell>Сумма списания</TableCell>
                                <TableCell>Дата списания</TableCell>
                                <TableCell>Статус</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paymentHistory.map((payment, index) => (
                                <TableRow key={payment.orderId}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{payment.orderId}</TableCell>
                                    <TableCell>
                                        {mapSubscriptionType(payment.subscription.type)}
                                    </TableCell>
                                    <TableCell>{payment.amount} ₽</TableCell>
                                    <TableCell>
                                        {new Date(payment.createdAt).toLocaleDateString(LOCALE_MAP[locale] ?? "ru-RU")}
                                    </TableCell>
                                    <TableCell>{mapPaymentStatus(payment.status)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}
        </Box>
    );
};
