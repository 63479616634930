import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import useLocale from '../../../../CustomHooks/useLocale';
import { useStores } from '../../../../root-store-context';
import { useValidationSchema } from '../../../../CustomHooks/useValidationSchema';
import { useAlertContext } from '../../../Context/AlertContext';
import CustomContainer from '../../CustomContainer/CustomContainer';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import VKAuthButton from '../VKAuthButton/VKAuthButton';
import CircularProgressWrapper from '../../CircularProgressWrapper/CircularProgressWrapper';
import TextFieldWrapper from '../../TextFieldWrapper/TextFieldWrapper';
import Alert from '@mui/material/Alert';
import { NAVIGATION_ROUTES } from '../../../../const';
import classes from './LoginForm.module.css';
import GoogleAuthButton from '../GoogleAuthButton/GoogleAuthButton';
import CRAuthButton from '../CRAuthButton/CRAuthButton';

const LoginForm = () => {
    const intl = useIntl();
    const { localeUrlPrefix } = useLocale();
    const { user } = useStores();
    const navigate = useNavigate();
    const [urlSearchParams] = useSearchParams();
    const retpath = urlSearchParams.get('retpath');
    const showAlert = useAlertContext();

    const { currentPasswordSchema, emailSchema } = useValidationSchema();

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const [showUserError, setShowUserError] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const handleClickShowErrors = () => {
        setShowErrors(true);
    };

    useEffect(() => {
        const handleKeyDown = (event: { key: string }) => {
            if (event.key === 'Escape') {
                setShowUserError(false);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const formik = useFormik({
        initialValues: {
            email: user.lastLoginEmail,
            password: ''
        },
        onSubmit: async (values) => {
            await user.login(values.email, values.password);

            if (user.state !== 'ok' && user.error !== 'user not found') {
                showAlert(
                    `${intl.formatMessage({
                        defaultMessage: 'Произошла ошибка:',
                        id: 'Register_changeError',
                        description: 'форма регистрации'
                    })} ${user.error}`,
                    'error'
                );
            }

            if (user.error === 'user not found') {
                setShowUserError(true);
            }

            if (user.isLogined) {
                // TODO проверить retpath!!!
                navigate(retpath ? retpath : `${localeUrlPrefix}${NAVIGATION_ROUTES.cabinet}`);
            }
        },
        validationSchema: yup.object({
            email: emailSchema,
            password: currentPasswordSchema
        })
    });

    const { isSubmitting } = formik;

    return (
        <section className={classes.login} aria-labelledby="login-page-title">
            <CustomContainer>
                <Typography
                    variant="h1"
                    component="h1"
                    color="primary"
                    id="login-page-title"
                    className={classes['login__title']}
                >
                    <FormattedMessage
                        defaultMessage="Вход"
                        id="Login_0QRfWL"
                        description="форма входа"
                    />
                </Typography>

                <form
                    onSubmit={formik.handleSubmit}
                    className={classes['login__form']}
                    aria-label={intl.formatMessage({
                        description: 'Форма входа',
                        id: 'Register_loginFormTitle',
                        defaultMessage: 'форма регистрации'
                    })}
                >
                    <TextFieldWrapper
                        errorText={formik.errors.email}
                        errorId="emailError"
                        showErrors={showErrors}
                    >
                        <TextField
                            type="email"
                            name="email"
                            label={intl.formatMessage({
                                defaultMessage: 'Ваш e-mail',
                                id: 'Login_ZJKmL5',
                                description: 'форма входа'
                            })}
                            autoComplete="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            error={showErrors && !!formik.errors.email}
                            inputProps={{
                                'aria-describedby': 'emailError'
                            }}
                        />
                    </TextFieldWrapper>

                    <TextFieldWrapper
                        errorText={formik.errors.password}
                        errorId="passwordError"
                        showErrors={showErrors}
                    >
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="password">
                                {intl.formatMessage({
                                    defaultMessage: 'Пароль',
                                    id: 'Login_+oubRC',
                                    description: 'форма входа'
                                })}
                            </InputLabel>
                            <OutlinedInput
                                type={showPassword ? 'text' : 'password'}
                                label={intl.formatMessage({
                                    defaultMessage: 'Пароль',
                                    id: 'Login_+oubRC',
                                    description: 'форма входа'
                                })}
                                name="password"
                                id="password"
                                autoCapitalize="off"
                                autoCorrect="off"
                                autoComplete="current-password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                error={showErrors && !!formik.errors.password}
                                inputProps={{
                                    'aria-describedby': 'passwordError'
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label={intl.formatMessage({
                                                defaultMessage: 'Показать пароль',
                                                id: 'Register_Gs+e1b7',
                                                description: 'форма'
                                            })}
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </TextFieldWrapper>

                    <CircularProgressWrapper isSubmitting={isSubmitting} fullWidth>
                        <Button
                            fullWidth
                            variant="contained"
                            type="submit"
                            size="large"
                            onClick={handleClickShowErrors}
                            disabled={isSubmitting}
                        >
                            {intl.formatMessage({
                                defaultMessage: 'Войти',
                                id: 'Login_ToZ8fw',
                                description: 'форма входа'
                            })}
                        </Button>
                    </CircularProgressWrapper>
                </form>
                <div className={classes['login__options']}>
                    <p className={classes['login__options-message']}>
                        <FormattedMessage
                            defaultMessage="или"
                            id="Login_optionsMessage"
                            description="форма входа"
                        />
                    </p>
                    {intl.locale === 'ru' && (
                        <>
                            <VKAuthButton />
                            <CRAuthButton />
                        </>
                    )}

                    {intl.locale === 'en' && <GoogleAuthButton />}
                </div>
                <div className={classes['login__actions']}>
                    <Link
                        to={`${localeUrlPrefix}${NAVIGATION_ROUTES.registration}`}
                        className={classes['login__link']}
                    >
                        {intl.formatMessage({
                            defaultMessage: 'Нет аккаунта? Зарегистрируйтесь',
                            id: 'Login_2UD/1F',
                            description: 'форма входа'
                        })}
                    </Link>
                    <Link
                        to={`${localeUrlPrefix}${NAVIGATION_ROUTES.password_recovery}`}
                        className={classes['login__link']}
                    >
                        {intl.formatMessage({
                            defaultMessage: 'Забыли пароль?',
                            id: 'Login_nbOqA9',
                            description: 'форма входа'
                        })}
                    </Link>
                </div>
                {showUserError && (
                    <div className={classes['login__error-wrapper']}>
                        <Alert
                            variant="filled"
                            onClose={() => {
                                setShowUserError(false);
                            }}
                            severity="error"
                            className={classes['login__error']}
                        >
                            <FormattedMessage
                                defaultMessage="Введён неправильный пароль или пользователь с таким e-mail не найден"
                                id="Login_userErrorMessage"
                                description="форма входа"
                            />
                        </Alert>
                    </div>
                )}
            </CustomContainer>
        </section>
    );
};

export default observer(LoginForm);
