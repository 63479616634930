import { useEffect, useState } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { buildApiUrl } from '../../../../services/api';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import StarIcon from '@mui/icons-material/Star';

export async function fetchSegmentResults(): Promise<Result | null> {
    try {
        const response = await fetch(buildApiUrl(`/user-progress`), {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch user progress');
        }

        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Failed to fetch user progress:', error);
        return null;
    }
}

type Result = {
    gamer?: {
        currentXP: number;
        currentLevel: number;
    };
    bestPowerMetrics?: {
        watts5sec: number;
        watts1min: number;
        watts5min: number;
        watts20min: number;
        watts1hr: number;
    };
};

function CabinetProgress() {
    const [results, setResults] = useState<Result | null>(null);

    useEffect(() => {
        const loadResults = async () => {
            const data = await fetchSegmentResults();
            setResults(data);
        };

        loadResults();
    }, []);

    return (
        <Box mt={4}>
            <Typography variant="h3">Мой прогресс</Typography>

            {!results ? (
                <Typography>Данные о прогрессе отсутствуют.</Typography>
            ) : (
                <>
                    <Box display="flex" alignItems="center" gap={1}>
                        <DirectionsBikeIcon color="primary" />
                        <Typography variant="h6">
                            Текущий уровень: {results.gamer?.currentLevel ?? 'Нет данных'}
                        </Typography>
                    </Box>

                    <Box display="flex" alignItems="center" gap={1} mt={1}>
                        <StarIcon color="secondary" />
                        <Typography variant="h6">
                            Текущий опыт: {results.gamer?.currentXP ?? 'Нет данных'}
                        </Typography>
                    </Box>

                    <Typography variant="h5" mt={3} gutterBottom>
                        Лучшая мощность за последние 30 дней
                    </Typography>

                    <TableContainer sx={{ maxWidth: '500px' }} component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Интервал</TableCell>
                                    <TableCell align="right">Мощность (Вт)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[
                                    { label: '5 секунд', value: results.bestPowerMetrics?.watts5sec },
                                    { label: '1 минута', value: results.bestPowerMetrics?.watts1min },
                                    { label: '5 минут', value: results.bestPowerMetrics?.watts5min },
                                    { label: '20 минут', value: results.bestPowerMetrics?.watts20min },
                                    { label: '1 час', value: results.bestPowerMetrics?.watts1hr }
                                ].map((row) => (
                                    <TableRow key={row.label}>
                                        <TableCell>{row.label}</TableCell>
                                        <TableCell align="right">{row.value ?? 'Нет данных'}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </Box>
    );
}

export default CabinetProgress;
