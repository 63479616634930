import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { buildApiUrl } from '../../../../services/api';

import classes from './CRAuthButton.module.css';

function CRIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} sx={{ width: '30px', height: '30px', mt: '4px' }}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M31.842 8.54878C31.928 8.69926 32.0068 8.84258 32.0785 8.9859C32.2791 9.39435 32.4726 9.80279 32.6732 10.2112C32.7306 10.3259 32.7162 10.3976 32.6231 10.4835C30.7385 12.3037 28.8612 14.1309 26.9766 15.9582C25.9233 16.9829 24.8699 18.0076 23.8166 19.0323C23.7163 19.1255 23.6804 19.2115 23.7091 19.362C23.9742 20.8739 24.2322 22.3931 24.4901 23.9122C24.4973 23.9624 24.4973 24.0197 24.5045 24.0842C24.4471 24.0555 24.4042 24.0412 24.3612 24.0197C23.7449 23.6973 23.1287 23.3676 22.5053 23.0452C22.3978 22.9878 22.3548 22.909 22.3333 22.7944C22.0968 21.4257 21.8675 20.057 21.6239 18.6884C21.6024 18.5522 21.6311 18.4591 21.7314 18.3587C25.0634 15.127 28.3882 11.888 31.7131 8.65627C31.7561 8.62761 31.7919 8.59894 31.842 8.54878Z"
                    fill="currentColor"
                ></path>
                <path
                    d="M26.0463 12.0529C25.9173 12.189 25.8027 12.318 25.6737 12.4398C25.2652 12.8411 24.8568 13.2424 24.434 13.6365C24.3767 13.6938 24.2692 13.7296 24.1904 13.7153C22.8218 13.529 21.4531 13.3212 20.0845 13.1349C19.9125 13.1134 19.8194 13.0417 19.7406 12.8841C17.6984 8.74942 15.6562 4.61477 13.614 0.480108C13.5853 0.429947 13.5638 0.372621 13.5352 0.293797C13.9006 0.229305 14.2517 0.164813 14.6028 0.114653C14.8536 0.0788237 15.1044 0.0716579 15.3481 0.0214974C15.4985 -0.00716577 15.5559 0.0501605 15.6203 0.171979C16.2438 1.44032 16.8743 2.70867 17.4977 3.97701C18.6872 6.37755 19.8695 8.77809 21.059 11.1786C21.1092 11.2861 21.1808 11.3363 21.2955 11.3578C22.8218 11.5727 24.3409 11.7949 25.8671 12.0099C25.9173 12.0242 25.9818 12.0385 26.0463 12.0529Z"
                    fill="currentColor"
                ></path>
                <path
                    d="M9.95173 32.5398C9.83708 32.4897 9.72959 32.4467 9.62211 32.3894C9.18501 32.1601 8.74791 31.9236 8.31081 31.6943C8.20332 31.637 8.18183 31.5653 8.19616 31.4506C8.73358 28.3407 9.271 25.2379 9.80125 22.128C9.95889 21.2036 10.1165 20.2792 10.2813 19.362C10.31 19.2186 10.2742 19.1327 10.1739 19.0323C9.07752 17.9718 7.98835 16.9113 6.89918 15.8507C6.85619 15.8077 6.7917 15.7791 6.73438 15.7361C6.74154 15.7146 6.75587 15.7002 6.76304 15.6787C7.09265 15.6286 7.41511 15.5784 7.74472 15.5283C8.146 15.4709 8.54727 15.4065 8.94854 15.3563C9.0417 15.3491 9.16351 15.385 9.23517 15.4423C10.2527 16.424 11.263 17.4129 12.2805 18.3946C12.3737 18.4806 12.3665 18.5666 12.3522 18.6669C12.1301 19.9424 11.9151 21.2179 11.693 22.4934C11.3992 24.1989 11.1054 25.8972 10.8116 27.6026C10.5321 29.2149 10.2598 30.8201 9.98039 32.4324C9.98039 32.4682 9.96606 32.4897 9.95173 32.5398Z"
                    fill="currentColor"
                ></path>
                <path
                    d="M0 15.127C0.0429936 14.8045 0.0788215 14.5035 0.128981 14.1954C0.17914 13.8945 0.250796 13.6007 0.300955 13.2997C0.322452 13.1779 0.379777 13.1349 0.501592 13.1205C2.4578 12.8411 4.42117 12.5544 6.37738 12.2678C8.31209 11.9884 10.2468 11.7089 12.1815 11.4294C12.2245 11.4223 12.2675 11.4079 12.3033 11.4079C12.7548 11.4438 12.9554 11.1786 13.1345 10.7988C13.7293 9.53765 14.3599 8.29081 14.9833 7.04396C15.0119 6.9938 15.0334 6.93647 15.0764 6.86481C15.1122 6.93647 15.1481 6.97947 15.1696 7.03679C15.4705 7.65305 15.7786 8.26931 16.0796 8.8784C16.1298 8.97872 16.1369 9.05755 16.0868 9.15787C15.4562 10.419 14.8328 11.6802 14.2165 12.9414C14.1664 13.0417 14.1091 13.0919 13.9944 13.1062C12.8694 13.2639 11.7516 13.4287 10.6337 13.5935C9.26511 13.7941 7.89648 13.9948 6.52786 14.1882C5.17356 14.3817 3.8121 14.5824 2.4578 14.7758C1.69108 14.8833 0.924362 14.998 0.157643 15.1055C0.11465 15.1198 0.0716559 15.1198 0 15.127Z"
                    fill="currentColor"
                ></path>
                <path
                    d="M12.5586 26.3199C12.6517 25.7968 12.7306 25.3024 12.8166 24.8008C12.8595 24.5715 12.9097 24.3422 12.9312 24.1129C12.9455 23.9696 13.0315 23.9122 13.139 23.8549C14.3142 23.2386 15.4893 22.6367 16.6501 22.0061C16.8938 21.8772 17.0729 21.8772 17.3165 22.0061C19.8245 23.339 22.3396 24.6503 24.8548 25.976C26.3882 26.7785 27.9216 27.5811 29.4479 28.3837C29.4981 28.4123 29.5482 28.441 29.6127 28.4768C29.5625 28.5342 29.5267 28.5772 29.4837 28.6202C29.0896 29.0071 28.6955 29.3869 28.3086 29.7738C28.2154 29.867 28.1366 29.8813 28.0148 29.8168C26.6533 29.0931 25.2919 28.3837 23.9304 27.6671C21.6804 26.4848 19.4304 25.3096 17.1804 24.1272C17.0299 24.0484 16.9153 24.0484 16.772 24.1272C15.432 24.8366 14.092 25.5317 12.7449 26.2339C12.7019 26.2554 12.6446 26.2769 12.5586 26.3199Z"
                    fill="currentColor"
                ></path>
                <path
                    d="M5.85997 29.9315C5.67367 29.7667 5.50169 29.6305 5.35121 29.4801C4.99293 29.1289 4.64899 28.7635 4.29071 28.4124C4.20472 28.3264 4.19039 28.2404 4.20472 28.1257C4.40536 26.9864 4.59883 25.847 4.79946 24.7076C4.82096 24.593 4.83529 24.4783 4.85679 24.3207C4.70631 24.3637 4.5845 24.3995 4.46268 24.4425C3.70313 24.6861 2.94357 24.9298 2.18402 25.1878C2.06221 25.2307 1.99055 25.2164 1.93323 25.0874C1.70393 24.6002 1.47463 24.12 1.23816 23.6328C1.188 23.5325 1.188 23.4751 1.31698 23.4393C3.29469 22.8015 5.26523 22.1638 7.23577 21.5189C7.24293 21.5189 7.25726 21.5189 7.30026 21.5189C6.82733 24.3135 6.34723 27.0939 5.85997 29.9315Z"
                    fill="currentColor"
                ></path>
                <path
                    d="M22.1884 7.68889C20.9129 5.10921 19.6589 2.57252 18.3906 0C18.6916 0.0286631 18.9567 0.0429947 19.2147 0.0859894C19.6589 0.157647 20.1032 0.243637 20.5546 0.315295C20.6764 0.336792 20.7409 0.394118 20.7911 0.501605C21.3214 1.5908 21.8659 2.67284 22.3962 3.75487C22.4249 3.81936 22.4607 3.87669 22.5108 3.96985C22.7043 3.70471 22.8835 3.46824 23.0554 3.2246C23.428 2.71583 23.8006 2.1999 24.1733 1.69113C24.2163 1.62663 24.2521 1.55498 24.3524 1.6123C24.8898 1.89893 25.4272 2.1784 25.979 2.4722C24.7107 4.22065 23.4639 5.93327 22.1884 7.68889Z"
                    fill="currentColor"
                ></path>
                <path
                    d="M5.92523 3.98418C7.19354 5.73264 8.44035 7.45242 9.70866 9.20088C6.87109 9.60933 4.06934 10.0178 1.24609 10.4334C1.26042 10.3689 1.26759 10.3259 1.28192 10.2972C1.59004 9.68815 1.89816 9.07189 2.21345 8.4628C2.24928 8.39831 2.34243 8.34098 2.41409 8.32665C3.6394 8.14034 4.86472 7.96836 6.0972 7.78922C6.14736 7.78205 6.19752 7.76772 6.28351 7.74622C5.69593 6.93649 5.12268 6.14825 4.52794 5.33135C4.98653 4.87274 5.44513 4.43563 5.92523 3.98418Z"
                    fill="currentColor"
                ></path>
                <path
                    d="M33.622 21.0531C31.5655 20.3867 29.5448 19.7274 27.4883 19.061C29.5448 17.0617 31.5727 15.0912 33.6435 13.0776C33.7295 13.5648 33.8155 14.002 33.8943 14.4462C33.9373 14.6899 33.9588 14.9407 33.9946 15.1915C34.0161 15.3348 33.9875 15.4351 33.88 15.5426C33.0201 16.3667 32.1674 17.2051 31.3075 18.0363C31.2574 18.0864 31.2144 18.1366 31.1284 18.2226C32.0886 18.5379 33.013 18.8389 33.9731 19.147C33.8585 19.7776 33.7438 20.401 33.622 21.0531Z"
                    fill="currentColor"
                ></path>
                <path
                    d="M18.3008 27.5954C20.8374 28.9211 23.3382 30.2396 25.882 31.5724C25.7458 31.6584 25.6312 31.7372 25.5165 31.7946C24.9863 32.0669 24.456 32.3392 23.9186 32.5971C23.847 32.633 23.7323 32.6115 23.6606 32.5756C22.593 32.0239 21.5253 31.4578 20.4576 30.8988C20.3931 30.863 20.3215 30.8415 20.2212 30.7914C20.2212 30.8917 20.2212 30.9633 20.2212 31.035C20.2212 31.8662 20.214 32.6975 20.2283 33.5215C20.2283 33.6935 20.1925 33.7723 20.0062 33.7938C19.5118 33.844 19.0245 33.9228 18.5372 33.9873C18.4656 33.9945 18.3939 33.9873 18.3151 33.9873C18.3008 31.8591 18.3008 29.7452 18.3008 27.5954Z"
                    fill="currentColor"
                ></path>
                <path
                    d="M2.87528 18.1939C2.2877 17.6207 1.72162 17.0689 1.11971 16.4885C1.42783 16.4455 1.69295 16.4025 1.96525 16.3667C2.44534 16.295 2.91827 16.2233 3.39837 16.166C3.46286 16.1589 3.55601 16.1804 3.60617 16.2233C4.56636 17.1477 5.51938 18.0793 6.47241 19.0037C6.47957 19.0108 6.47957 19.0252 6.4939 19.0538C4.46604 19.7131 2.43818 20.3652 0.381651 21.0316C0.309995 20.6876 0.238339 20.3652 0.173849 20.0355C0.130855 19.7991 0.0950271 19.5554 0.0520335 19.3118C0.0233711 19.1685 0.0591991 19.104 0.209677 19.0538C1.02655 18.803 1.83627 18.5307 2.64598 18.2728C2.71763 18.2584 2.78212 18.2298 2.87528 18.1939Z"
                    fill="currentColor"
                ></path>
                <path
                    d="M11.7906 7.67459C10.5223 5.9333 9.27548 4.22068 8 2.46506C8.55892 2.17127 9.09634 1.88463 9.63376 1.61233C9.66958 1.59084 9.77707 1.641 9.8129 1.69116C10.3288 2.38624 10.8376 3.08849 11.3463 3.78357C11.3822 3.83373 11.4252 3.88389 11.4896 3.96988C11.8551 3.22464 12.2134 2.51522 12.5788 1.77715C12.6935 2.00645 12.8009 2.21426 12.9013 2.42207C13.1306 2.89501 13.367 3.36079 13.5892 3.83373C13.6178 3.89105 13.625 3.99138 13.5963 4.04154C13.0231 5.21672 12.4355 6.39191 11.8551 7.57427C11.8479 7.59577 11.8264 7.61726 11.7906 7.67459Z"
                    fill="currentColor"
                ></path>
                <path
                    d="M28.0725 3.99139C28.4523 4.35684 28.8249 4.7008 29.1903 5.05192C29.4841 5.33855 29.477 5.33855 29.2333 5.66818C28.7676 6.30594 28.3018 6.93652 27.8432 7.57428C27.8074 7.61727 27.7787 7.66744 27.7285 7.74626C28.5454 7.86808 29.3193 7.98273 30.1004 8.09738C29.9069 8.29802 29.7349 8.49867 29.5414 8.68498C29.2047 9.0146 28.8607 9.33706 28.5311 9.67386C28.4451 9.75985 28.3663 9.79567 28.2373 9.77418C26.9618 9.58787 25.6935 9.40156 24.418 9.22241C24.3822 9.21525 24.3464 9.20091 24.2891 9.18658C25.5502 7.45963 26.797 5.73984 28.0725 3.99139Z"
                    fill="currentColor"
                ></path>
                <path
                    d="M29.1323 24.3207C29.2684 25.1376 29.4046 25.9115 29.5407 26.7069C29.4619 26.6711 29.4117 26.6567 29.3687 26.6281C28.7596 26.3128 28.1506 25.9903 27.5415 25.6822C27.434 25.6248 27.3839 25.5604 27.3624 25.4385C27.1546 24.1989 26.9396 22.9592 26.7246 21.7195C26.7103 21.655 26.7103 21.5905 26.7031 21.4974C28.7453 22.1566 30.7589 22.8159 32.8225 23.4823C32.5359 24.0699 32.2636 24.6431 31.9842 25.2307C31.0311 24.9369 30.0996 24.636 29.1323 24.3207Z"
                    fill="currentColor"
                ></path>
                <path
                    d="M13.7785 30.7699C13.0476 31.1497 12.3526 31.5151 11.636 31.8877C11.636 31.8089 11.6288 31.7516 11.636 31.7014C11.7506 31.0135 11.8725 30.3256 11.9943 29.6377C12.0086 29.5804 12.0516 29.5015 12.1089 29.4729C13.2769 28.8566 14.4449 28.2475 15.6129 27.6313C15.6344 27.6241 15.6559 27.6169 15.6917 27.6098C15.6917 29.738 15.6917 31.8519 15.6917 33.9945C15.5198 33.9801 15.3549 33.973 15.1901 33.9515C14.7674 33.8942 14.3518 33.8297 13.929 33.7795C13.7928 33.7652 13.7713 33.7007 13.7713 33.5789C13.7785 32.7261 13.7713 31.8734 13.7713 31.0135C13.7785 30.9562 13.7785 30.8917 13.7785 30.7699Z"
                    fill="currentColor"
                ></path>
            </svg>
        </SvgIcon>
    );
}

const CRAuthButton = () => {
    return (
        <>
            <Button
                href={`${buildApiUrl('/connection/oauth/login')}?redirectUrl=${encodeURIComponent(
                    document.location.href
                )}&appId=cyclingRace`}
                startIcon={<CRIcon />}
                fullWidth
                size="large"
                variant="contained"
                className={classes['cr-auth-button']}
            >
                <FormattedMessage
                    defaultMessage="Войти через CyclingRace"
                    id="Cabinet_EnterCR"
                    description="кабинет"
                />
            </Button>
        </>
    );
};

export default CRAuthButton;
