import { useStores } from '../../../root-store-context';
import { observer } from 'mobx-react-lite';
import { NavLink, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import useLocale from '../../../CustomHooks/useLocale';
import { useTheme } from '@mui/material/styles';
import { NAVIGATION_ROUTES, SCROLL_OFFSET } from '../../../const';
import classes from './MainMenu.module.css';

type MainMenuProps = {
    isScrolled?: boolean;
    className?: string;
};

function MainMenu({ isScrolled = false, className = '' }: MainMenuProps): React.JSX.Element {
    const { localeUrlPrefix } = useLocale();
    const theme = useTheme();
    const { user } = useStores();
    const location = useLocation();

    const color = isScrolled ? theme.palette.secondary.main : theme.palette.light.main;

    const scrollTo = () => {
        const sectionElement = document.getElementById(
            location.hash.slice(1, location.hash.length)
        );
        const offset = SCROLL_OFFSET;
        if (sectionElement) {
            const targetScroll = sectionElement.offsetTop - offset;
            sectionElement.scrollIntoView({ behavior: 'smooth' });
            window.scrollTo({
                top: targetScroll
            });
        }
    };

    return (
        <ul
            className={`${classes['main-menu']} ${className}`}
            style={{
                color: color
            }}
        >
            <li className={classes['main-menu__item']}>
                <NavLink
                    className={classes['main-menu__link']}
                    to={`${localeUrlPrefix}${NAVIGATION_ROUTES.how_to_start}`}
                    onClick={scrollTo}
                >
                    <FormattedMessage
                        id="Header_B1QVBw"
                        defaultMessage="Как начать"
                        description="элемент меню"
                    />
                </NavLink>
            </li>
            <li className={classes['main-menu__item']}>
                <NavLink
                    className={classes['main-menu__link']}
                    to={`${localeUrlPrefix}${NAVIGATION_ROUTES.maps}`}
                    onClick={scrollTo}
                >
                    <FormattedMessage
                        id="Header_hm2zoA"
                        defaultMessage="Карты"
                        description="элемент меню"
                    />
                </NavLink>
            </li>
            <li className={classes['main-menu__item']}>
                <NavLink
                    className={classes['main-menu__link']}
                    to={`${localeUrlPrefix}${NAVIGATION_ROUTES.workouts}`}
                    onClick={scrollTo}
                >
                    <FormattedMessage
                        id="Header_HkU1aZ"
                        defaultMessage="Тренировки"
                        description="элемент меню"
                    />
                </NavLink>
            </li>
            <li className={classes['main-menu__item']}>
                <NavLink
                    className={classes['main-menu__link']}
                    to={`${localeUrlPrefix}${NAVIGATION_ROUTES.about}`}
                    onClick={scrollTo}
                >
                    <FormattedMessage
                        id="Header_Rl27q3"
                        defaultMessage="О нас"
                        description="элемент меню"
                    />
                </NavLink>
            </li>
            {user.isLogined && (
                <li className={classes['main-menu__item']}>
                    <NavLink
                        className={classes['main-menu__link']}
                        to={`${localeUrlPrefix}${NAVIGATION_ROUTES.cabinet_subscription}`}
                    >
                        <FormattedMessage
                            id="Header_J9oe0h"
                            defaultMessage="Подписка"
                            description="элемент меню"
                        />
                    </NavLink>
                </li>
            )}
            {!user.isLogined && (
                <li className={classes['main-menu__item']}>
                    <NavLink
                        className={classes['main-menu__link']}
                        to={`${localeUrlPrefix}${NAVIGATION_ROUTES.subscription}`}
                        onClick={scrollTo}
                    >
                        <FormattedMessage
                            id="Header_J9oe0h"
                            defaultMessage="Подписка"
                            description="элемент меню"
                        />
                    </NavLink>
                </li>
            )}
            <li className={classes['main-menu__item']}>
                <NavLink
                    className={classes['main-menu__link']}
                    to={`${localeUrlPrefix}${NAVIGATION_ROUTES.help}`}
                >
                    <FormattedMessage
                        id="Header_8h6EWK"
                        defaultMessage="Помощь"
                        description="элемент меню"
                    />
                </NavLink>
            </li>
            <li className={classes['main-menu__item']}>
                <NavLink
                    className={classes['main-menu__link']}
                    to={`${localeUrlPrefix}${NAVIGATION_ROUTES.download}`}
                    onClick={scrollTo}
                >
                    <FormattedMessage
                        id="Header_LT99Hk"
                        defaultMessage="Скачать"
                        description="элемент меню"
                    />
                </NavLink>
            </li>
            <li className={classes['main-menu__item']}>
                <NavLink
                    className={classes['main-menu__link']}
                    to={`${localeUrlPrefix}${NAVIGATION_ROUTES.segment_result}`}
                    onClick={scrollTo}
                >
                    Соревнования
                </NavLink>
            </li>

            {user.isLogined &&
                location.pathname !== `${localeUrlPrefix}${NAVIGATION_ROUTES.cabinet}` && (
                    <li className={classes['main-menu__item']}>
                        <NavLink
                            className={classes['main-menu__link']}
                            to={`${localeUrlPrefix}${NAVIGATION_ROUTES.cabinet}`}
                        >
                            <FormattedMessage
                                id="Header_FgmjAT"
                                defaultMessage="Кабинет"
                                description="элемент меню"
                            />
                        </NavLink>
                    </li>
                )}

            {!user.isLogined && (
                <li className={classes['main-menu__item']}>
                    <NavLink
                        className={classes['main-menu__link']}
                        to={`${localeUrlPrefix}${NAVIGATION_ROUTES.login}`}
                    >
                        <FormattedMessage
                            id="Header_tNozdx"
                            defaultMessage="Войти"
                            description="элемент меню"
                        />
                    </NavLink>
                </li>
            )}
        </ul>
    );
}

export default observer(MainMenu);
