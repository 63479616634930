import { Link } from 'react-router-dom';
import useIntl from 'react-intl/src/components/useIntl';
import useLocale from '../../../CustomHooks/useLocale';
import { useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { FormattedMessage } from 'react-intl';
import CustomContainer from '../CustomContainer/CustomContainer';
import Typography from '@mui/material/Typography';
import Socials from '../Socials/Socials';
import { WattAttackLogo } from '../WattAttackLogo';
import { NAVIGATION_ROUTES } from '../../../const';
import AF_Capital_Logo from '../../../assets/images/af_capital_vu_logo.webp';
import Volga_Union_logo from '../../../assets/images/volga_union_logo.webp';
import CR_logo from '../../../assets/images/cr_logo.webp';
import classes from './MainFooter.module.css';

function MainFooter() {
    const intl = useIntl();
    const theme = useTheme();
    const { localeUrlPrefix } = useLocale();
    return (
        <footer className={classes['main-footer']}>
            <CustomContainer className={classes['main-footer__container']}>
                <Typography component="h2" sx={visuallyHidden}>
                    <FormattedMessage
                        defaultMessage="Контактная информация"
                        id="contactsTitle"
                        description="футер"
                    />
                </Typography>
                <div className={classes['main-footer__copyright']}>
                    <Link to="/">
                        <WattAttackLogo width="245" height="43" />
                    </Link>
                    {intl.locale === 'ru' && (
                        <Typography variant="body2" className={classes['main-footer__requisites']}>
                            <b>ООО «Ваттаттак»</b>
                            <br />
                            ОГРН 1237300014126
                            <br />
                            ИНН 7300027704, КПП 730001001,
                            <br />
                            Юридический адрес: 432048, Ульяновская область, г. Ульяновск,
                            Университетская Набережная ул, д. 13, кв. 68,
                            <br />
                            Директор Попов Сергей Эдуардович
                            <br />
                            +7 916 389 61 68
                        </Typography>
                    )}
                    <div>
                        <Link
                            className={`${classes['main-footer__link']} ${classes['main-footer__link--policy']}`}
                            to={`${localeUrlPrefix}${NAVIGATION_ROUTES.privacy_policy}`}
                        >
                            <FormattedMessage
                                defaultMessage="Политика конфиденциальности"
                                id="Footer_privacyPolicy"
                                description="футер"
                            />
                        </Link>
                        <a
                            className={`${classes['main-footer__link']} ${classes['main-footer__link--policy']}`}
                            href="/offer_ru.pdf"
                        >
                            <FormattedMessage
                                defaultMessage="Оферта об оказании услуг"
                                id="Footer_offer"
                                description="футер"
                            />
                        </a>
                        <Link
                            className={`${classes['main-footer__link']} ${classes['main-footer__link--policy']}`}
                            to={`${localeUrlPrefix}${NAVIGATION_ROUTES.subscription_support}`}
                        >
                            <FormattedMessage
                                defaultMessage="Поддержка по вопросам подписки и оплаты"
                                id="Footer_SubscrSupport"
                                description="футер"
                            />
                        </Link>
                    </div>
                </div>
                <div className={classes['main-footer__partners']}>
                    <Typography
                        className={classes['main-footer__heading']}
                        variant="h5"
                        component="h3"
                    >
                        <FormattedMessage
                            defaultMessage="Официальные партнеры"
                            id="Footer_R34S0D"
                            description="футер"
                        />
                    </Typography>
                    <a href='https://volgaunion.com/' target='_blank' rel="noreferrer">
                        <img className={classes['main-footer__image']} src={CR_logo} alt="Логотип Cycling race." width="300" height="42" />
                    </a>
                    <a href='https://volgaunion.com/' target='_blank' rel="noreferrer">
                        <img className={classes['main-footer__image']} src={Volga_Union_logo} alt="Логотип команды Волга Юнион." width="250" height="93" />
                    </a>
                </div>
                <div className={classes['main-footer__contacts']}>
                    <Typography
                        className={`${classes['main-footer__heading']} ${classes['main-footer__heading--end']}`}
                        variant="h5"
                        component="h3"
                    >
                        <FormattedMessage
                            defaultMessage="Связаться с нами"
                            id="Footer_R4k00D"
                            description="футер"
                        />
                    </Typography>
                    <Typography variant="subtitle1" textAlign="left" component="p">
                        <a
                            className={classes['main-footer__link']}
                            href={`mailto:${intl.formatMessage({
                                defaultMessage: 'info@wattattack.com',
                                id: 'Footer_GPbstX',
                                description: 'футер'
                            })}`}
                        >
                            <FormattedMessage
                                defaultMessage="info@wattattack.com"
                                id="Footer_GPbstX"
                                description="футер"
                            />
                        </a>
                    </Typography>

                    {intl.locale === 'ru' && (
                        <>
                            <Typography sx={visuallyHidden}>
                                <FormattedMessage
                                    defaultMessage="Наши социальные сети"
                                    id="Footer_jOXCxK"
                                    description="футер"
                                />
                            </Typography>
                            <Socials color={theme.palette.common.white} />
                        </>
                    )}
                </div>
            </CustomContainer>
        </footer>
    );
}

export default MainFooter;
