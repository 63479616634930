import AppLayout from '../../layouts/AppLayout/AppLayout';
import MainFooter from '../../Components/MainFooter/MainFooter';
import MainHeader from '../../Components/MainHeader/MainHeader';
import SegmentResults from '../../Components/SegmentResults/SegmentResults';

function SegmentResultsPage() {
    return (
        <AppLayout>
            <MainHeader variant="cabinet" />
            <main>
                <SegmentResults />
            </main>
            <MainFooter />
        </AppLayout>
    );
}

export default SegmentResultsPage;
