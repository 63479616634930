import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { buildApiUrl } from '../../../../services/api';
import { useStores } from '../../../../root-store-context';
import useLocale from '../../../../CustomHooks/useLocale';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION_ROUTES } from '../../../../const';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import nadezhdinIslandImage from '../../../../assets/images/tracks/Nadezhdin-island.webp';
import richVarietyImage from '../../../../assets/images/tracks/Rich-variety.webp';
import lighthouseImage from '../../../../assets/images/tracks/Lighthouse.webp';
import krylatskoeImage from '../../../../assets/images/tracks/Krylatskoe.webp';
import lakesImage from '../../../../assets/images/tracks/lakes.webp';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';


const mapImages: Record<number, { img: string }> = {
    1: {
        img: nadezhdinIslandImage
    },
    2: {
        img: lakesImage
    },
    3: {
        img: krylatskoeImage
    },
    4: {
        img: lighthouseImage
    },
    5: {
        img: richVarietyImage
    },
};

type Activity = {
    id: number;
    name: string | null;
    description: string | null;
    distance: number;
    startTime: string;
    movingTime: number;
    elapsedTime: number;
    fitFileId: number;
    mapNameRu: string;
    mapId: number;
    maxWatts: number;
    averageWatts: number;
    maxCadence: number;
    averageCadence: number;
    maxHeartrate: number;
    averageHeartrate: number;
    totalElevationGain: number;
};

function formatTime(seconds: number): string {
    const date = new Date(seconds * 1000); // Convert seconds to milliseconds
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const sec = date.getUTCSeconds();
    return `${hours}:${minutes.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`;
}

function formatDistance(meters: number): string {
    return (meters / 1000).toFixed(2); // Convert meters to kilometers with 2 decimal places
}

function CabinetActivities() {
    const [activities, setActivities] = useState<Activity[]>([]);
    const [totalDistance, setTotalDistance] = useState(0);
    const [totalElapsedTime, setTotalElapsedTime] = useState(0);
    const { user } = useStores();
    const navigate = useNavigate();
    const { localeUrlPrefix } = useLocale();

    useEffect(() => {
        if (!user.isLogined) {
            navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.login}`);
        }
    }, [user.isLogined, localeUrlPrefix, navigate]);

    useEffect(() => {
        fetch(buildApiUrl('/activities'), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(async (res) => {
                const data = await res.json();

                if (data.activities) {
                    setActivities(data.activities);
                    setTotalDistance(data.totalDistance || 0);
                    setTotalElapsedTime(data.totalElapsedTime || 0);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }, []);

    const recentActivities = activities.slice(0, 30);

    return (
        <>
            <section>
                <h2>Обзор тренировок</h2>
                <p>Общее пройденное за всё время расстояние: {formatDistance(totalDistance)} км</p>
                <p>Общее время: {formatTime(totalElapsedTime)}</p>
            </section>

            <section>
                <h2>Мои последние активности</h2>
                <div>
                    {recentActivities.length === 0 && (
                        <p>У вас пока нет загруженных активностей.</p>
                    )}

                    <Grid container spacing={3}>
                        {recentActivities.map((activity) => (
                            <Grid item xs={12} sm={6} md={4} key={activity.id}>
                                <Card sx={{ display: 'flex', marginBottom: 2, borderRadius: 2, boxShadow: 3 }}>
                                    {activity.mapId && mapImages[activity.mapId] ? (
                                        <CardMedia
                                            component="img"
                                            sx={{ width: 150, height: 150, objectFit: 'cover' }}
                                            image={mapImages[activity.mapId].img}
                                            alt={activity.mapNameRu}
                                        />
                                    ) : (
                                        <Box sx={{ width: 150, height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f5f5f5' }}>
                                            <DirectionsBikeIcon sx={{ fontSize: 60, color: '#9e9e9e' }} />
                                        </Box>
                                    )}
                                    <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2 }}>
                                        <CardContent>
                                            <Typography variant="h5" gutterBottom>
                                                Тренировка от&nbsp;{new Intl.DateTimeFormat('ru-RU').format(new Date(activity.startTime))}
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                <strong>Карта:</strong> {activity.mapNameRu}
                                            </Typography>

                                            <Typography variant="body2">
                                                <strong>Дистанция:</strong> {formatDistance(activity.distance)} км
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Время:</strong> {formatTime(activity.elapsedTime)}
                                            </Typography>

                                            <Typography variant="body2" gutterBottom>
                                                <strong>Средняя мощность:</strong> {activity.averageWatts} Вт
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                <strong>Максимальная мощность:</strong> {activity.maxWatts} Вт
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                <strong>Средний каденс:</strong> {activity.averageCadence} об/мин
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                <strong>Максимальный каденс:</strong> {activity.maxCadence} об/мин
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                <strong>Средний пульс:</strong> {activity.averageHeartrate} уд/мин
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                <strong>Максимальный пульс:</strong> {activity.maxHeartrate} уд/мин
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                <strong>Общий набор высоты:</strong> {activity.totalElevationGain} м
                                            </Typography>

                                            {activity.fitFileId && (
                                                <Typography variant="body2">
                                                    <a href={buildApiUrl(`/activity/download/${activity.fitFileId}`)} download>
                                                        Скачать FIT-файл
                                                    </a>
                                                </Typography>
                                            )}
                                        </CardContent>
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </section>
        </>
    );
}

export default observer(CabinetActivities);
